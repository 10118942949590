import * as React from "react"

const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={127.822}
    height={37.244}
    viewBox="0 0 127.822 37.244"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="Path_2209"
          data-name="Path 2209"
          d="M0,12.428H127.822V-24.816H0Z"
          transform="translate(0 24.816)"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Group_4017" data-name="Group 4017" transform="translate(0 24.816)">
      <g
        id="Group_4016"
        data-name="Group 4016"
        transform="translate(0 -24.816)"
        clipPath="url(#clip-path)"
      >
        <g
          id="Group_4010"
          data-name="Group 4010"
          transform="translate(47.737 0)"
        >
          <path
            id="Path_2203"
            data-name="Path 2203"
            d="M10.944,12.422-.254-24.556h-10.4l-11.2,36.978h7.117L-11.453,2.07H.443L3.627,12.422ZM-.9-3.653h-9.257l3.135-10.2c.546-1.841.9-3.833,1.294-5.823h.447a52.265,52.265,0,0,0,1.3,5.823Zm23.1-20.9H15.229V12.422H22.2Zm0,36.978,36.037,0L49.523-2.658c3.932-1.691,6.371-4.628,6.371-10.252v-.348c0-9.755-7.366-11.547-16.275-11.547-3.732,0-7.067.1-10.351.249V12.422h6.767V-1.214h3.435c1.144,0,2.239,0,3.234-.05l8.163,13.686ZM49.076-12.86c0,4.777-2.836,6.122-9.207,6.122-1.294,0-2.538,0-3.833-.05V-18.932c9.059,0,13.04.05,13.04,5.774Z"
            transform="translate(21.853 24.805)"
            fill="#3c3b36"
          />
        </g>
        <g
          id="Group_4011"
          data-name="Group 4011"
          transform="translate(20.262 20.524)"
        >
          <path
            id="Path_2204"
            data-name="Path 2204"
            d="M0,1.394V13.935H16.719V-2.784H4.783C1.87-2.784,0-1.652,0,1.394"
            transform="translate(0 2.784)"
            fill="#6f6f6a"
          />
        </g>
        <g
          id="Group_4012"
          data-name="Group 4012"
          transform="translate(20.262 20.524)"
        >
          <path
            id="Path_2205"
            data-name="Path 2205"
            d="M0,1.394V13.935H16.719S6.991.132,4.783-2.784C1.87-2.784,0-1.652,0,1.394"
            transform="translate(0 2.784)"
            fill="#3c3b36"
          />
        </g>
        <g id="Group_4013" data-name="Group 4013" transform="translate(0 0)">
          <path
            id="Path_2206"
            data-name="Path 2206"
            d="M0,3.226.017,30.8h10.8V8.54a3.961,3.961,0,0,1,3.97-3.952H37V-6.441H9.686A9.257,9.257,0,0,0,0,3.226"
            transform="translate(0 6.441)"
            fill="#96b337"
          />
        </g>
        <g id="Group_4014" data-name="Group 4014" transform="translate(0 0)">
          <path
            id="Path_2207"
            data-name="Path 2207"
            d="M0,3.226V22.443l10.818-8.359V8.54a3.961,3.961,0,0,1,3.97-3.952h-.012l3.9,0L10.818-6.441H9.686A9.257,9.257,0,0,0,0,3.226"
            transform="translate(0 6.441)"
            fill="#66791d"
          />
        </g>
        <g id="Group_4015" data-name="Group 4015" transform="translate(0 0)">
          <path
            id="Path_2208"
            data-name="Path 2208"
            d="M0,3.226.017,30.8h10.8V-6.441H9.686A9.257,9.257,0,0,0,0,3.226"
            transform="translate(0 6.441)"
            fill="#b0d243"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SVGComponent
