import React from "react"

const SVGComponent = (props) => (
  <svg
    id="Group_4602"
    data-name="Group 4602"
    xmlns="http://www.w3.org/2000/svg"
    width={70}
    height={70}
    viewBox="0 0 70 70"
    {...props}
  >
    <path id="Path_2630" data-name="Path 2630" d="M0,0H70V70H0Z" fill="none" />
    <path
      id="Path_2631"
      data-name="Path 2631"
      d="M54.924,4H7.88A5.909,5.909,0,0,0,2.029,9.951L2,45.658a5.934,5.934,0,0,0,5.88,5.951H54.924a5.934,5.934,0,0,0,5.88-5.951V9.951A5.934,5.934,0,0,0,54.924,4Zm0,11.9L31.4,30.78,7.88,15.9V9.951L31.4,24.829,54.924,9.951Z"
      transform="translate(3.598 7.195)"
      fill="#bccf00"
    />
  </svg>
)

export default SVGComponent
