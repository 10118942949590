import React from "react"
import { FormattedHTMLMessage } from "gatsby-plugin-intl"
import JetztBestellen from "../assets/homepage/08-kontakt/jetzt-bestellen.png"
import Iconcontact from "../assets/svg /IconContact"

const Kontakt = () => {
  return (
    <div>
      <div className="bg-white mx-auto py-5 hidden lg:block"></div>
      <div className="py-5 lg:py-10 bg-morning_green text-center ">
        <img
          src={JetztBestellen}
          alt="Jetzt Bestellen"
          className="mx-auto object-fill inset-x-0 lg:mb-16 w-24 md:w-1/6 "
        />
        <div
          className="mt-8 pt-12 h-64 lg:h-96 xl:container bg-white xl:mx-auto 
                    uppercase font-bold text-sm lg:text-xl xl:text-2xl mx-4 md:mx-10 "
        >
          <p className="lg:pt-10 text-text_morning_coffee w-8/12 mx-auto">
            <FormattedHTMLMessage id="homepage-contact.text" />
          </p>
          <Iconcontact className=" m-auto object-fill inset-x-0 my-5" />
          <div
            className="m-auto object-fill inset-x-0 xl:w-4/12 bg-morning_green text-white uppercase 
                         font-bold p-2 text-sm lg:text-xl xl:text-2xl w-max"
          >
            <a
              // href="mailto:air@relution.io?subject=Anfrage Relution Air"
              href="https://relution.io/contact/"
              target="_blank"
              rel="noreferrer"
            >
              <FormattedHTMLMessage id="homepage-contact.text-contact-btn" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Kontakt
