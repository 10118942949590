import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import LogoIcon from "../components/assets/svg /LogoIcon"

function Header() {
  const [isExpanded] = useState(false)

  return (
    <nav className="mb-2 bg-white fixed z-50 w-full shadow">
      <div className="mx-auto xl:container flex pt-2 sm:pt-8 sm:mb-3">
        <div className="flex items-center flex-shrink-0  text-white px-1 md:px-10 xl:px-0">
          <Link
            to={`/`}
            href="#responsive-header"
            className="block my-2 lg:my-4 transform scale-75 md:scale-100"
          >
            <LogoIcon />
          </Link>
        </div>
        {/* <div
          className={`${
            isExpanded ? `block` : `hidden`
          } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}
        > */}
        {/* <div className="text-xl lg:flex-grow">
            <Link
              to={`/page-2`}
              className="block ml-10 lg:inline-block lg:mt-10 uppercase text-morning_coffee no-underline font-bold hover:text-morning_green"
            >
              Kontakt
            </Link>
          </div> */}
        {/* </div> */}
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
