import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import { FormattedHTMLMessage, injectIntl } from "gatsby-plugin-intl"
import "../components/style/layout.css"
import Footer from "../components/footer"
import Navbar from "../components/navbar"
import Hero from "./templates/Hero"
import Card from "./templates/fragments/Card"
import Contact from "./templates/Contact"

const Layout = ({ children, intl }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <Navbar />
      <Hero />
      {/* id-homepage-intro-text */}
      <Card>
        <div className="bg-white xl:container mx-auto font-body px-5 md:px-10 xl:px-0 py-12">
          <h2
            className="font-bold leading-tight uppercase block text-lg md:text-2xl  
                               2xl:w-max text-morning_coffee pl-0.5 pb-3
                              "
          >
            <FormattedHTMLMessage id="homepage-intro-text.title" />
          </h2>
          <span className=" text-morning_coffee text-sm md:text-lg 2xl:text-2xl font-light leading-relaxed">
            <FormattedHTMLMessage id="homepage-intro-text.text" />
          </span>
        </div>
      </Card>
      <main className="bg-bg_coffee text-morning_coffee font-body">
        {children}
      </main>
      <Contact />

      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
