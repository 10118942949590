import React from "react"

const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={60}
    height={40}
    viewBox="0 0 85 55"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <path
          id="Path_1973"
          data-name="Path 1973"
          d="M0,15.673H29.965v-29.3H0Z"
          transform="translate(0 13.625)"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Group_4684" data-name="Group 4684" transform="translate(-1382)">
      <g id="Group_4665" data-name="Group 4665" transform="translate(1382)">
        <rect
          id="Rectangle_30"
          data-name="Rectangle 30"
          width={85}
          height={55}
          rx={8}
          fill="#bccf00"
        />
      </g>
      <g
        id="Group_4667"
        data-name="Group 4667"
        transform="translate(1409.518 12.852)"
      >
        <g id="Group_2640" data-name="Group 2640" clipPath="url(#clip-path)">
          <g
            id="Group_2637"
            data-name="Group 2637"
            transform="translate(0.932 9.665)"
          >
            <path
              id="Path_1970"
              data-name="Path 1970"
              d="M3.41,9.973a1,1,0,0,1-1.019.989H-1.945a1,1,0,0,1-1.019-.989V-7.681A1,1,0,0,1-1.945-8.67H2.39a1,1,0,0,1,1.019.989Z"
              transform="translate(2.964 8.67)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_2638"
            data-name="Group 2638"
            transform="translate(-0.001 0)"
          >
            <path
              id="Path_1971"
              data-name="Path 1971"
              d="M2.2,4.28a4.062,4.062,0,0,1-4.12-4,4.062,4.062,0,0,1,4.12-4,4.062,4.062,0,0,1,4.12,4,4.062,4.062,0,0,1-4.12,4"
              transform="translate(1.916 3.721)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_2639"
            data-name="Group 2639"
            transform="translate(10.177 9.369)"
          >
            <path
              id="Path_1972"
              data-name="Path 1972"
              d="M10.585,10.175a.923.923,0,0,1-.938.91H5a.923.923,0,0,1-.938-.91V1.894c0-1.234.374-5.412-3.322-5.412-2.869,0-3.449,2.86-3.567,4.142v9.552a.923.923,0,0,1-.935.91h-4.5a.923.923,0,0,1-.935-.91V-7.639a.923.923,0,0,1,.935-.91h4.5a.923.923,0,0,1,.935.91V-6.1c1.064-1.55,2.645-2.746,6.008-2.746,7.451,0,7.408,6.761,7.408,10.476Z"
              transform="translate(9.202 8.845)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SVGComponent
