import React from "react"
import "../components/style/layout.css"
import LogoIcon from "./assets/svg /LogoIcon"
import RelutionLogo from "./assets/svg /RelutionLogo"
import Twitter from "./assets/svg /Twitter"
import Linkedin from "./assets/svg /Linkedin"
// import DownloadBtn from "./assets/svg /DownloadBtn"
import GoogleBtn from "./assets/homepage/04-sensore/googleplay.png"
import AppleBtn from "./assets/homepage/04-sensore/appstore.png"

const Footer = () => (
  <footer className="xl:container mx-auto font-normal text-text_morning_coffee">
    <div className="px-5 md:px-10 xl:px-0 pb-4 lg:pb-10">
      <div className="sm:flex sm:flex-row justify-between">
        {/* 01 */}
        <div className="pt-10 sm:pt-20 xl:pl-0 sm:w-2/4">
          <LogoIcon />
          <p className="my-5">Relution Air ist eine Marke der Relution GmbH</p>
          <p className="text-left">
            Relution GmbH
            <br />
            Daimlerstraße 133 <br />
            70372 Stuttgart
          </p>
          <p className="mt-5">Telefax +49 (0) 711 25254 700</p>
          <p className="mb-5">Telefon +49 (0) 711 25254 600</p>
        </div>
        {/* 02 */}
        <div className="sm:w-2/4 grid sm:justify-items-end ">
          <div className="sm:mt-10">
            <a href="https://relution.io" target="_blank" rel="noreferrer">
              <div className="pt-10 xl:px-0">
                <RelutionLogo />
              </div>
            </a>
          </div>
          <div className="flex justify-start sm:justify-end py-2 md:py-0">
            <a
              href="https://twitter.com/Relution_IO"
              title="RelutionTwitter"
              target="_blank"
              rel="noreferrer"
              className=""
            >
              <Twitter />
            </a>
            <a
              href="https://www.linkedin.com/company/m-way-solutions/"
              title="LinkedIn"
              target="_blank"
              rel="noreferrer"
              className="pl-2"
            >
              <Linkedin />
            </a>
          </div>
          <div className="pb-6">
            <div className="">
              <a
                href="https://apps.apple.com/de/app/relution-air/id1600460366?l=de"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={AppleBtn}
                  alt="Apple Store Logo"
                  className="max-w-xs h-9 md:h-10 "
                ></img>
              </a>
            </div>
            <div className="py-1">
              <a
                href="https://play.google.com/store/apps/details?id=com.mwaysolutions.relution_air&gl=DE"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={GoogleBtn}
                  alt="Google Store Logo"
                  className="max-w-xs h-9 md:h-10"
                ></img>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="sm:flex sm:flex-row justify-between font-normal text-xs">
        <div className="xl:pl-0">
          <a
            href="https://relution.io/imprint/"
            target="_blank"
            rel="noreferrer"
            className="px-0.5"
          >
            Impressum |
          </a>
          <a
            href="https://relution.io/privacy-policy/"
            target="_blank"
            rel="noreferrer"
            className="px-0.5"
          >
            Datenschutzerklärung
          </a>
          {/* <a
            href="https://relution.io"
            target="_blank"
            rel="noreferrer"
            className="px-0.5"
          >
            Kontakt |
          </a>
          <a
            href="https://relution.io"
            target="_blank"
            rel="noreferrer"
            className="px-0.5"
          >
            Cookie-Einstellungen
          </a> */}
        </div>

        <div className="sm:text-right lg:text-center text-xs">
          <p className="">Copyright © 2022 All Right Reserved Relution GmbH</p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
