import React from "react"
import RelutionAirBg from "../assets/homepage/01-hero/graphic.png"
import RelutionAirMobile from "../assets/homepage/01-hero/relutionair-mobile-version.png"

// import HeroBg from "../assets/homepage/01-hero/bg_gradient.png"
// import HeroGrapics from "../assets/homepage/01-hero/graphic.png"

// import RelutionAirBgMobile from "../assets/homepage/01-hero/relution-air-mobile.png"
import { FormattedHTMLMessage } from "gatsby-plugin-intl"
// import MorningGreenTitle from "./fragments/MorningGreenTitle"

const Hero = () => (
  <section>
    <a
      // href="mailto:air@relution.io?subject=Anfrage Relution Air"
      href="https://relution.io/contact/"
      target="_blank"
      rel="noreferrer"
    >
      <div className="air-bg">
        <div className="xl:container mx-auto py-10 relative">
          <img
            src={RelutionAirBg}
            alt="Background Relution Air"
            className="mx-auto px-20 2xl:px-10 min-w-full pt-44 md:block hidden"
          />
          {/* <img
          src={RelutionAirBgMobile}
          alt="section_image"
          className="mx-auto px-10 min-w-full sm:pb-20 pt-10 lg:pb-10 block lg:hidden transform sm:scale-75 "
        /> */}
          <div
            className="absolute px-5 sm:px-10 lg:px-20 2xl:px-10 
          bottom-20 lg:bottom-32 xl:bottom-28 2xl:bottom-44 xxl:bottom-56"
          >
            {/* id-homepage-intro-text */}
            <h1 className="uppercase text-morning_coffee break-normal">
              <span className="text-5xl" translate="no" lang="de">
                Relution
              </span>
              <span className="text-morning_coffee font-extrabold text-5xl">
                Air
              </span>

              <span
                className="md:bg-morning_green uppercase md:text-white 
                               text-left px-2 py-0.5 w-max font-bold mb-1
                               tracking-wider  xl:text-3xl sm:text-xl hidden sm:block"
              >
                <FormattedHTMLMessage id="homepage-intro-text.hero-headline-01-row" />
              </span>

              <span
                className="md:bg-morning_green uppercase font-bold md:text-white px-2 py-0.5 w-max 
                                  tracking-wider xl:text-3xl sm:text-xl hidden sm:block"
              >
                <FormattedHTMLMessage id="homepage-intro-text.hero-headline-02-row" />
              </span>
            </h1>
          </div>
        </div>

        {/* small screen */}
        <div className="block md:hidden text-center pb-6">
          <img
            src={RelutionAirMobile}
            alt="Relutin Air Mobile Display"
            className="mx-auto s:w-8/12 -mt-10"
          />
          <h1 className=" text-morning_coffee uppercase font-normal mx-auto">
            <span className="text-xl s:text-4xl" translate="no" lang="de">
              RELUTION
            </span>
            <span className="text-morning_coffee font-extrabold text-xl s:text-4xl ">
              AIR
            </span>
            <span
              className=" mb-0.5 xs:bg-morning_green text-text_morning_coffee
            xs:text-white w-max mx-auto uppercase font-bold px-1 hidden
            s:block "
            >
              <FormattedHTMLMessage id="homepage-intro-text.hero-headline-01-row" />
            </span>
            <span
              className="xs:bg-morning_green xs:text-white text-text_morning_coffee 
                      mx-auto uppercase w-max font-bold px-1 hidden s:block "
            >
              <FormattedHTMLMessage id="homepage-intro-text.hero-headline-02-row" />
            </span>
          </h1>
        </div>
      </div>
    </a>
  </section>
)

export default Hero
