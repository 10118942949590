import React from "react"

const SVGComponent = (props) => (
  <svg
    height={40}
    viewBox="0 0 85 55"
    width={60}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#bccf00" height={55} rx={8} width={85} />
    <path
      d="m19.546 3.512a22.477 22.477 0 0 1 -1.96 1.568s.82 3.62-1.473 9.1-7.237 9.727-12.494 10.656-8.035.364-10.17-.267a17.578 17.578 0 0 1 -4.429-2.291 21.39 21.39 0 0 0 6.025-.7 11.6 11.6 0 0 0 3.631-1.993 8.158 8.158 0 0 1 -4.4-1.991c-1.943-1.793-2.038-3.122-2.038-3.122a10.345 10.345 0 0 0 2.007.234 4.548 4.548 0 0 0 1.148-.067 8.056 8.056 0 0 1 -4.3-3.422c-1.531-2.617-1.15-3.617-1.15-3.617a4.969 4.969 0 0 0 1.593.828 2.605 2.605 0 0 0 1.626.1 6.048 6.048 0 0 1 -2.805-4.61 7.782 7.782 0 0 1 .8-4.582 13.252 13.252 0 0 0 6.309 5.313c4.526 1.927 7.267 1.793 7.267 1.793a6.629 6.629 0 0 1 3.951-7.7c5.355-2.328 7.651 1.79 7.651 1.79a18.348 18.348 0 0 0 2.465-.932 4.883 4.883 0 0 0 1.268-.973 6.5 6.5 0 0 1 -.953 2.096 14.769 14.769 0 0 1 -1.674 1.843s1.054-.275 1.985-.6 1.53-.566 1.53-.566a6.22 6.22 0 0 1 -1.41 2.116"
      fill="#fff"
      transform="translate(37.512 16.825)"
    />
  </svg>
)

export default SVGComponent
